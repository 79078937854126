import React, { useEffect } from 'react';
import { Fade } from 'react-awesome-reveal';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';

// i18next
import { useTranslation } from 'react-i18next';

// Styles
import './MenuPage.styles.css';

// Components
import BreadcrumbComponent from './../../components/BreadcrumbComponent/BreadcrumbComponent';
import MenuCategoryComponent from './../../components/MenuCategoryComponent/MenuCategoryComponent';

const MenuPage = () => {
	// i18next
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang);
		// eslint-disable-next-line
	}, [lang]);

	const breadcrumbItems = [
		{
			title: t('words:breadcrumb.home'),
			href: `/${lang}`,
			isActive: false,
		},
		{
			title: t('words:breadcrumb.menu'),
			href: '',
			isActive: true,
		},
	];

	// Redux
	const { menu, isMenuLoading } = useSelector((state) => state.menu);

	// Scroll To Top On Initial Render
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}, [lang]);

	return (
		!isMenuLoading && (
			<Container
				fluid
				lang={lang}
				dir={lang === 'en' ? 'ltr' : 'rtl'}
				id='menu-page'
				className='page p-0'
			>
				{/* Breadcrumb */}
				<BreadcrumbComponent
					title={t('words:breadcrumb.menu')}
					items={breadcrumbItems}
				/>

				<Container>
					{/* Menu Categories */}
					<Row xs={1} className='g-4'>
						{menu.map((menuCategory, index) => (
							<Fade key={index} delay={`${index * 10}`}>
								<Col>
									<MenuCategoryComponent
										menuCategory={menuCategory}
										height='6rem'
									/>
								</Col>
							</Fade>
						))}
					</Row>
				</Container>
			</Container>
		)
	);
};

export default MenuPage;
