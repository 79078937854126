import React from 'react';
import { Fade } from 'react-awesome-reveal';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

// Styles
import './MenuCategoryComponent.styles.css';

// Components
import MenuItemComponent from './../MenuItemComponent/MenuItemComponent';

const MenuCategoryComponent = ({
	menuCategory,
	itemsCount = null,
	height = '1rem',
}) => {
	const { lang } = useParams();

	return (
		<Container as={Card} body className='menu-category overflow-hidden px-0'>
			<Row xs={1} className='g-3'>
				<Col
					className='category-head position-relative d-flex justify-content-between align-items-center'
					style={{
						'--image-url': `url(${menuCategory.image})`,
						'--height': height,
					}}
				>
					{/* Title */}
					<h2 className='title text-uppercase'>{menuCategory.title}</h2>

					{/* Items Count */}
					<h2 className='items-count d-flex justify-content-center align-items-center'>
						{new Intl.NumberFormat(
							lang === 'en' ? 'en-US' : 'ar-EG',
							{}
						).format(menuCategory.numberOfItems)}
					</h2>
				</Col>

				{/* Items */}
				<Col>
					<Row
						xs={1}
						md={itemsCount ? 1 : 2}
						lg={itemsCount ? 1 : 3}
						className='g-3'
					>
						{menuCategory.items
							.filter(
								(_, index) => index < (itemsCount ?? menuCategory.items.length)
							)
							.map((menuItem, index) => (
								<Fade key={index} direction='up' delay={`${(index + 1) * 10}`}>
									<Col>
										<MenuItemComponent key={index} menuItem={menuItem} />
									</Col>
								</Fade>
							))}
					</Row>
				</Col>
			</Row>
		</Container>
	);
};

export default MenuCategoryComponent;
