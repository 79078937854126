import { createSlice } from '@reduxjs/toolkit';

// Slice
export const cateringSlice = createSlice({
	name: 'catering',
	initialState: {
		catering: [],
		cateringPageDetails: {},
		isCateringLoading: true,
	},
	reducers: {
		cateringSuccess: (state, action) => {
			state.catering = action.payload.catering;
			state.cateringPageDetails = action.payload.catering_page;
			state.isCateringLoading = false;
		},

		startLoading: (state) => {
			state.isCateringLoading = true;
		},

		hasError: (state, action) => {
			state.error = action.payload;
			state.isCateringLoading = false;
		},
	},
});

export default cateringSlice.reducer;
