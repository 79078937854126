const BASE_URL = {
	local: 'http://localhost:8000/api',
	// demo: 'https://demoyoursite.net/asmaa/adam-rest-back/public/api',
	demo: "https://adam-rest.com/back/api"
};
export const constructURL = (infoType, searchParams = {}) => {
	const url = new URL(`${BASE_URL.demo}/${infoType}`);
	url.search = new URLSearchParams({ ...searchParams });

	return url;
};

export const checkURL = (urlLink) => {
	return urlLink.includes('http://') || urlLink.includes('https://')
		? urlLink
		: `https://${urlLink}`;
};

export const fixGoogleMaps = (urlLink) => {
	return urlLink.replace('www.google.com', 'maps-api-ssl.google.com');
};

export const trimEmptyTags = (htmlText, tag = 'p') => {
	const re = new RegExp(`<${tag}>&nbsp;</${tag}>`, 'gi');

	return htmlText.replace(re, '');
};
