import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

// Reducers
import aboutData from '../reducers/aboutData.reducer';
import catering from '../reducers/catering.reducer';
import contactsData from '../reducers/contactsData.reducer';
import gallery from '../reducers/gallery.reducer';
import homeData from '../reducers/homeData.reducer';
import menu from '../reducers/menu.reducer';
import openingTimes from '../reducers/openingTimes.reducer';
import ramadanOffersData from '../reducers/ramadanOffersData.reducer';
import settingsData from '../reducers/settingsData.reducer';
import specialOffersData from '../reducers/specialOffersData.reducer';

const reducer = combineReducers({
	homeData,
	aboutData,
	settingsData,
	contactsData,
	menu,
	catering,
	gallery,
	openingTimes,
	ramadanOffersData,
	specialOffersData,
});

const store = configureStore({
	reducer: reducer,
	devTools: false,
});

export default store;
