import { GB, SA } from 'country-flag-icons/react/3x2';
import React, { useEffect, useState } from 'react';
import { Container, Image, Nav, Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { constructURL } from '../../helpers/general';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { fetchCatering } from '../../actions/catering.action';
import { fetchGallery } from '../../actions/gallery.action';
import { fetchMenu } from '../../actions/menu.action';
import { fetchOpeningTimes } from '../../actions/openingTimes.action';
import { fetchRamadanOffersData } from '../../actions/ramadanOffersData.action';
import { fetchSpecialOffersData } from '../../actions/specialOffersData.action';
import { fetchAboutData } from './../../actions/aboutData.action';
import { fetchContactsData } from './../../actions/contactsData.action';
import { fetchHomeData } from './../../actions/homeData.action';
import { fetchSettingsData } from './../../actions/settingsData.action';

// Styles
import 'country-flag-icons/3x2/flags.css';
import './NavbarComponent.styles.css';

// Components
import LoadingComponent from './../LoadingComponent/LoadingComponent';

const NavbarComponent = () => {
	// i18next
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang);
		// eslint-disable-next-line
	}, [lang]);

	// Redux
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(fetchHomeData(constructURL('home'), lang));
		dispatch(fetchSettingsData(constructURL('setting'), lang));
		dispatch(fetchContactsData(constructURL('contacts'), lang));
		dispatch(fetchAboutData(constructURL('about'), lang));
		dispatch(fetchMenu(constructURL('menu'), lang));
		dispatch(fetchCatering(constructURL('catering'), lang));
		dispatch(fetchGallery(constructURL('gallery'), lang));
		dispatch(fetchOpeningTimes(constructURL('opening-times'), lang));
		dispatch(fetchRamadanOffersData(constructURL('ramadan-offers'), lang));
		dispatch(fetchSpecialOffersData(constructURL('special-offers'), lang));
		// eslint-disable-next-line
	}, [lang]);

	const { settings, isSettingsLoading } = useSelector(
		(state) => state.settingsData
	);
	const { isHomeDataLoading } = useSelector((state) => state.homeData);
	const { isContactsDataLoading } = useSelector((state) => state.contactsData);
	const { isAboutDataLoading } = useSelector((state) => state.aboutData);
	const { isMenuLoading } = useSelector((state) => state.menu);
	const { isCateringLoading } = useSelector((state) => state.catering);
	const { isGalleryLoading } = useSelector((state) => state.gallery);
	const { isOpeningTimesLoading } = useSelector((state) => state.openingTimes);
	const { isRamadan, isRamadanOffersDataLoading } = useSelector(
		(state) => state.ramadanOffersData
	);
	const { isSpecial, isSpecialOffersDataLoading } = useSelector(
		(state) => state.specialOffersData
	);

	// Change Displayed Language
	const location = useLocation();
	const navigate = useNavigate();

	// States
	const [isExpanded, setIsExpanded] = useState(false);

	return isHomeDataLoading ||
		isSettingsLoading ||
		isContactsDataLoading ||
		isAboutDataLoading ||
		isMenuLoading ||
		isCateringLoading ||
		isGalleryLoading ||
		isRamadanOffersDataLoading ||
		isSpecialOffersDataLoading ||
		isOpeningTimesLoading ? (
		<LoadingComponent />
	) : (
		<Navbar
			lang={lang}
			dir={lang === 'en' ? 'ltr' : 'rtl'}
			id='navbar-component'
			bg='dark'
			variant='dark'
			fixed='top'
			expand='lg'
			expanded={isExpanded}
		>
			<Container>
				{/* Navbar Logo */}
				<Navbar.Brand
					as={NavLink}
					to={`/${lang}`}
					onClick={() => setIsExpanded(false)}
					className='m-0'
				>
					<Image
						fluid
						src={settings.logo}
						alt='company logo'
						className='text-capitalize'
					/>
				</Navbar.Brand>

				{/* Navbar Toggler */}
				<Navbar.Toggle onClick={() => setIsExpanded(!isExpanded)} />

				{/* Navbar Menu */}
				<Navbar.Collapse>
					<Nav
						className={`${
							lang === 'en' ? 'ms-auto' : 'me-auto'
						} text-uppercase`}
					>
						{/* Home */}
						<Nav.Link
							as={NavLink}
							to={`/${lang}`}
							end
							onClick={() => setIsExpanded(false)}
						>
							{t('words:navbar.home')}
						</Nav.Link>

						{/* About Us */}
						<Nav.Link
							as={NavLink}
							to={`/${lang}/about-us`}
							onClick={() => setIsExpanded(false)}
						>
							{t('words:navbar.aboutUs')}
						</Nav.Link>

						{/* Menu */}
						<Nav.Link
							as={NavLink}
							to={`/${lang}/menu`}
							onClick={() => setIsExpanded(false)}
						>
							{t('words:navbar.menu')}
						</Nav.Link>

						{/* Gallery */}
						<Nav.Link
							as={NavLink}
							to={`/${lang}/gallery`}
							onClick={() => setIsExpanded(false)}
						>
							{t('words:navbar.gallery')}
						</Nav.Link>

						{/* Catering */}
						<Nav.Link
							as={NavLink}
							to={`/${lang}/catering`}
							onClick={() => setIsExpanded(false)}
						>
							{t('words:navbar.catering')}
						</Nav.Link>

						{/* Ramadan Offers */}
						{isRamadan && (
							<Nav.Link
								as={NavLink}
								to={`/${lang}/ramadan-offers`}
								onClick={() => setIsExpanded(false)}
								className='offers'
							>
								{t('words:navbar.ramadanOffers')}
							</Nav.Link>
						)}

						{/* Ramadan Offers */}
						{isSpecial && (
							<Nav.Link
								as={NavLink}
								to={`/${lang}/special-offers`}
								onClick={() => setIsExpanded(false)}
								className='offers'
							>
								{t('words:navbar.specialOffers')}
							</Nav.Link>
						)}

						{/* Contact Us */}
						<Nav.Link
							as={NavLink}
							to={`/${lang}/contact-us`}
							onClick={() => setIsExpanded(false)}
						>
							{t('words:navbar.contactUs')}
						</Nav.Link>

						{/* Translate */}
						<Navbar.Text
							id='translation'
							role='button'
							className='px-lg-2 d-flex justify-content-start align-items-center text-uppercase'
							onClick={() => {
								setIsExpanded(false);
								navigate(
									location.pathname.replace(
										`/${lang}`,
										`/${lang === 'en' ? 'ar' : 'en'}`
									)
								);
							}}
							style={{
								fontFamily: lang === 'ar' ? 'Roboto' : 'Cairo',
								fontWeight: '500',
							}}
						>
							<span className={`${lang === 'en' ? 'me-2' : 'ms-2'}`}>
								{lang === 'en' ? <SA width='1.5rem' /> : <GB width='1.5rem' />}
							</span>
							{t('navbar.otherLanguage', { ns: 'words' })}
						</Navbar.Text>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
};

export default NavbarComponent;
