import React, { useEffect, useState } from 'react';
import { Fade, Zoom } from 'react-awesome-reveal';
import { Badge, Col, Container, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BsEnvelope, BsPhone } from 'react-icons/bs';
import { FaWhatsapp } from 'react-icons/fa';
import { FiPhoneCall } from 'react-icons/fi';
import { MdOutlineLocationOn } from 'react-icons/md';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

// Redux
import { useSelector } from 'react-redux';

// Images
import ClockIcon from './../../assets/icons/clock.svg';
import LocationIcon from './../../assets/icons/location.svg';

// Styles
import './HomePage.styles.css';

// Components
import AboutSectionComponent from '../../components/AboutSectionComponent/AboutSectionComponent';
import IconTextComponent from '../../components/IconTextComponent/IconTextComponent';
import MenuCategoryComponent from '../../components/MenuCategoryComponent/MenuCategoryComponent';
import VideoModalComponent from '../../components/VideoModalComponent/VideoModalComponent';
import ButtonComponent from './../../components/ButtonComponent/ButtonComponent';
import FeatureCardComponent from './../../components/FeatureCardComponent/FeatureCardComponent';
import SliderComponent from './../../components/SliderComponent/SliderComponent';

const HomePage = () => {
	const location = useLocation();

	// i18next
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang);
		// eslint-disable-next-line
	}, [lang]);

	// Redux
	const { settings, isSettingsLoading } = useSelector(
		(state) => state.settingsData
	);
	const { mobiles, telephones, emails, isContactsLoading } = useSelector(
		(state) => state.contactsData
	);
	const { features, aboutUs, catering, video, isHomeDataLoading } = useSelector(
		(state) => state.homeData
	);
	const { menu, isMenuLoading } = useSelector((state) => state.menu);
	const { openingTimes, isOpeningTimesLoading } = useSelector(
		(state) => state.openingTimes
	);

	// States
	const [videoModalShow, setVideoModalShow] = useState(false);

	// Display Form Errors
	const displayToast = (statusCode, message) => {
		switch (statusCode) {
			case 200:
				toast.success(message, {
					toastId: message,
				});
				break;
			case 400:
			case 404:
				toast.error(message, {
					toastId: message,
				});
				break;
			default:
				toast.error(t('sentences:errors.default'));
				break;
		}
	};

	const ICON = { SIZE: 24 };

	// Scroll To Top On Initial Render
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}, [lang]);

	// Display toast when receiving 404 error for (not found) pages
	useEffect(() => {
		location.state &&
			displayToast(location.state.status, location.state.message);
		// eslint-disable-next-line
	}, [location.state]);

	return (
		!isHomeDataLoading &&
		!isMenuLoading &&
		!isOpeningTimesLoading &&
		!isSettingsLoading &&
		!isContactsLoading && (
			<Container
				lang={lang}
				dir={lang === 'en' ? 'ltr' : 'rtl'}
				fluid
				id='home-page'
				className='page position-relative px-0'
			>
				{/* Home Slider */}
				<SliderComponent />

				{/* Features */}
				<Container id='features-section' className='position-relative'>
					<Row xs={1} md={2} lg={3}>
						{features.map((feature, index) => (
							<Fade key={index} direction='up' delay={`${index * 30}`}>
								<Col>
									<FeatureCardComponent feature={feature} />
								</Col>
							</Fade>
						))}
					</Row>
				</Container>

				{/* About */}
				<AboutSectionComponent
					id='about-section'
					subTitle={aboutUs.sub_title}
					title={aboutUs.title}
					description={aboutUs.description}
					images={aboutUs.images}
					hasButton={true}
					buttonRoute={`/${lang}/about-us`}
				/>

				{/* Catering */}
				<Container
					fluid
					id='catering-section'
					className='position-relative p-0'
				>
					<Container>
						<Row className='g-3 overflow-hidden'>
							{/* Image Container */}
							<Col xs={12} sm={3} className='image-container'>
								<Fade
									direction={lang === 'en' ? 'left' : 'right'}
									delay='20'
									className='h-100'
								>
									<Container
										fluid
										className='image overflow-hidden position-relative p-0 h-100'
									>
										<Image
											fluid
											src={catering.images[0].path}
											alt='about thumbnail'
											className='text-capitalize h-100'
											style={{
												objectFit: 'cover',
												objectPosition: 'center',
											}}
										/>
									</Container>
								</Fade>
							</Col>

							{/* Section Text */}
							<Col
								xs={12}
								sm={6}
								className='text-container d-flex flex-column justify-content-center align-items-center'
							>
								<Zoom delay='40'>
									<p className='title text-uppercase'>{catering.title}</p>
									<p
										dangerouslySetInnerHTML={{
											__html: catering.description,
										}}
										className='description'
									></p>
									<ButtonComponent
										text={t('words:buttons.viewCateringMenu')}
										link={`/${lang}/menu`}
										className='button'
										styles={{
											button: {
												width: 'fit-content',
											},
										}}
									/>
								</Zoom>
							</Col>

							{/* Image Container */}
							<Col xs={12} sm={3} className='image-container'>
								<Fade
									direction={lang === 'en' ? 'right' : 'left'}
									delay='20'
									className='h-100'
								>
									<Container
										fluid
										className='image overflow-hidden position-relative p-0 h-100'
									>
										<Image
											fluid
											src={catering.images[1].path}
											alt='about thumbnail'
											className='text-capitalize h-100'
											style={{
												objectFit: 'cover',
												objectPosition: 'center',
											}}
										/>
									</Container>
								</Fade>
							</Col>
						</Row>
					</Container>
				</Container>

				{/* Menu */}
				<Container id='menu-section' className='position-relative'>
					{/* Section Text */}
					<Row xs={1} className='text-container g-3'>
						<Fade direction='down' delay='10'>
							<Col className='section-subtitle'>{t('words:ourMenu')}</Col>
							<Col className='title text-uppercase'>
								{t('words:specialMenu')}
							</Col>
						</Fade>
					</Row>

					{/* Menu Categories */}
					<Row xs={1} md={2} xl={3} className='g-4'>
						{menu
							.filter((_, index) => index < 3)
							.map((menuCategory, index) => (
								<Fade key={index} delay={`${index * 10}`}>
									<Col>
										<MenuCategoryComponent
											menuCategory={menuCategory}
											itemsCount={4}
										/>
									</Col>
								</Fade>
							))}

						{/* View More - Menu */}
						<Col
							xs={12}
							className='w-100 d-flex justify-content-center align-items-center'
						>
							<Zoom delay='50'>
								<ButtonComponent
									text={t('words:buttons.viewMenu')}
									link={`/${lang}/menu`}
								/>
							</Zoom>
						</Col>
					</Row>
				</Container>

				{/* Video */}
				<Container
					fluid
					id='video-section'
					className='position-relative d-flex justify-content-center align-items-center'
					style={{
						'--image-url': `url(${video.poster})`,
					}}
				>
					<Image
						fluid
						src={require('./../../assets/images/video-player.png')}
						alt='video player'
						className='text-capitalize animate__animated animate__heartBeat animate__delay-2s animate__infinite animate__slower'
						style={{
							cursor: 'pointer',
							animationName: 'custom_heartBeat',
							animationTimingFunction: 'linear',
						}}
						onClick={() => setVideoModalShow(true)}
					/>

					<VideoModalComponent
						show={videoModalShow}
						onHide={() => setVideoModalShow(false)}
						video={{ url: video.link }}
					/>
				</Container>

				{/* Contact */}
				<Container id='contact-section' className='position-relative'>
					<Row className='g-3 overflow-hidden'>
						{/* Contact Details Container */}
						<Col
							xs={12}
							md={6}
							lg={4}
							className='text-container d-flex justify-content-center align-items-center'
						>
							<Fade
								direction={lang === 'en' ? 'left' : 'right'}
								delay='20'
								className='w-100 h-100'
							>
								<Container
									fluid
									className='text d-flex flex-column justify-content-center align-items-center p-0 h-100'
								>
									{/* Icon */}
									<Image
										fluid
										src={LocationIcon}
										alt='location icon'
										className='text-capitalize'
									/>

									{/* Title */}
									<h3 className='text-center text-uppercase my-4'>
										{t('sentences:findUs')}
									</h3>

									{/* Contact Info */}
									<Row
										as='ul'
										className='w-100 d-flex flex-column justify-content-center'
									>
										{/* Address */}
										<Col as='li'>
											<IconTextComponent
												icon={<MdOutlineLocationOn />}
												text={settings.address}
												isCentered={false}
												styles={{
													container: {
														'--size': ICON.SIZE,
														marginBottom: '0.5rem',
													},
												}}
											/>
										</Col>

										{/* Telephones */}
										{telephones.length > 0 && (
											<Col as='li'>
												<IconTextComponent
													icon={<FiPhoneCall />}
													text={telephones
														.filter((_, index) => index < 1)
														.map((telephone, index) => (
															<Container
																fluid
																key={index}
																className='value p-0'
																style={{
																	direction: 'ltr',
																}}
															>
																<a href={`tel:${telephone.contact}`}>
																	{telephone.contact}
																</a>
															</Container>
														))}
													isCentered={false}
													styles={{
														container: {
															'--size': ICON.SIZE,
															marginBottom: '0.5rem',
														},
													}}
												/>
											</Col>
										)}

										{/* Phones */}
										{mobiles.length > 0 && (
											<Col as='li'>
												<IconTextComponent
													icon={<BsPhone />}
													text={mobiles
														.filter((_, index) => index < 1)
														.map((mobile, index) => (
															<Container
																fluid
																key={index}
																className='value p-0'
																style={{
																	direction: 'ltr',
																}}
															>
																<a href={`tel:${mobile.contact}`}>
																	{mobile.contact}
																</a>
															</Container>
														))}
													isCentered={false}
													styles={{
														container: {
															'--size': ICON.SIZE,
															marginBottom: '0.5rem',
														},
													}}
												/>
											</Col>
										)}

										{/* Emails */}
										{emails.length > 0 && (
											<Col as='li'>
												<IconTextComponent
													icon={<BsEnvelope />}
													text={emails
														.filter((_, index) => index < 1)
														.map((email, index) => (
															<Container
																fluid
																key={index}
																className='value p-0'
															>
																<a href={`mailto:${email.contact}`}>
																	{email.contact}
																</a>
															</Container>
														))}
													isCentered={false}
													isCapitalized={false}
													styles={{
														container: {
															'--size': ICON.SIZE,
														},
													}}
												/>
											</Col>
										)}
									</Row>
								</Container>
							</Fade>
						</Col>

						{/* Image Container */}
						<Col xs={12} md={12} lg={4} className='image-container'>
							<Fade delay='50' className='w-100 h-100'>
								<Container
									fluid
									className='image overflow-hidden position-relative p-0 w-100 h-100'
									style={{
										'--image-url': `url(${settings.contact_img})`,
									}}
								></Container>
							</Fade>
						</Col>

						{/* Opening Times Container */}
						<Col
							xs={12}
							md={{ span: 6, order: 'first' }}
							lg={{ span: 4, order: 'last' }}
							className='text-container d-flex flex-column justify-content-center align-items-center'
						>
							<Fade
								direction={lang === 'en' ? 'right' : 'left'}
								delay='20'
								className='w-100 h-100'
							>
								<Container
									fluid
									className='text h-100 d-flex flex-column justify-content-center align-items-center p-0'
								>
									{/* Icon */}
									<Image
										fluid
										src={ClockIcon}
										alt='location icon'
										className='text-capitalize'
									/>

									{/* Title */}
									<h3 className='text-center text-uppercase my-4'>
										{t('words:openingTime')}
									</h3>

									{/* Periods */}
									<Row
										as='ul'
										className='w-100 d-flex flex-column justify-content-center'
									>
										{openingTimes.map((openingTime, index) => (
											<Col key={index} className='opening-time-item' as='li'>
												<span className='text-uppercase'>{`${openingTime.day}:`}</span>
												{openingTime.isClosed ? (
													<Badge className='text-uppercase' bg='danger'>
														{t('words:isClosed')}
													</Badge>
												) : (
													<span>
														{t('sentences:fromTo', {
															from: openingTime.from,
															to: openingTime.to,
														})}
													</span>
												)}
											</Col>
										))}
									</Row>
								</Container>
							</Fade>
						</Col>
					</Row>

					{/* Default Number */}
					<Row
						className='g-3 overflow-hidden'
						style={{
							marginTop: '3rem',
						}}
					>
						<Fade direction='down' delay='20'>
							<Col xs={12} className='default-number-container'>
								<FaWhatsapp size={80} color={'#e99c60'} />

								<Container
									fluid
									className={`p-0 ${lang === 'en' ? 'ps-5' : 'pe-5'}`}
								>
									<p className='section-subtitle subtitle'>
										{t('words:appointment')}
									</p>

									<h3 className='title text-uppercase d-flex align-items-center flex-wrap'>
										<span className={`${lang === 'en' ? 'me-3' : 'ms-3'}`}>
											{t('sentences:makeAppointment')}
										</span>
										<span
											className='number'
											style={{
												direction: 'ltr',
											}}
										>
											<a href={`tel:${settings.whatsapp}`}>
												{settings.whatsapp}
											</a>
										</span>
									</h3>
								</Container>
							</Col>
						</Fade>
					</Row>
				</Container>
			</Container>
		)
	);
};

export default HomePage;
