import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Navigate, useParams } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';

// i18next
import { useTranslation } from 'react-i18next';

// Styles
import './RamadanOffersPage.styles.css';

// Components
import AboutSectionComponent from '../../components/AboutSectionComponent/AboutSectionComponent';
import BreadcrumbComponent from '../../components/BreadcrumbComponent/BreadcrumbComponent';

const RamadanOffersPage = () => {
	// i18next
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang);
		// eslint-disable-next-line
	}, [lang]);

	const breadcrumbItems = [
		{
			title: t('words:breadcrumb.home'),
			href: `/${lang}`,
			isActive: false,
		},
		{
			title: t('words:breadcrumb.ramadanOffers'),
			href: '',
			isActive: true,
		},
	];

	// Redux
	const { offers, isRamadan, isRamadanOffersDataLoading } = useSelector(
		(state) => state.ramadanOffersData
	);

	// Scroll To Top On Initial Render
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}, [lang]);

	return (
		!isRamadanOffersDataLoading &&
		(!isRamadan ? (
			<Navigate
				to={`/${lang}`}
				state={{ status: 404, message: `${t('sentences:errors.404')}` }}
			/>
		) : (
			<Container
				fluid
				lang={lang}
				dir={lang === 'en' ? 'ltr' : 'rtl'}
				id='ramadan-offers-page'
				className='page p-0'
			>
				{/* Breadcrumb */}
				<BreadcrumbComponent
					title={t('words:breadcrumb.ramadanOffers')}
					items={breadcrumbItems}
				/>

				<Container>
					{offers.map((offer, index) => (
						<AboutSectionComponent
							key={index}
							title={offer.title}
							description={offer.description}
							images={offer.images.filter((_, index) => index < 4)}
							pathname='<object>.self'
						/>
					))}
				</Container>
			</Container>
		))
	);
};

export default RamadanOffersPage;
