import axios from 'axios';

// Reducers
import { aboutDataSlice } from '../reducers/aboutData.reducer';

// Actions
const { aboutDataSuccess, startLoading, hasError } = aboutDataSlice.actions;
export const fetchAboutData =
	(
		url = 'https://demoyoursite.net/asmaa/adam-rest-back/public/api/about',
		language = 'en'
	) =>
	async (dispatch) => {
		dispatch(startLoading());

		await axios({
			method: 'get',
			url: url,
			headers: {
				locale: language,
			},
		})
			.then((response) => {
				dispatch(aboutDataSuccess(response.data.data));
			})
			.catch((error) => dispatch(hasError(error.message)));
	};
