import React, { useEffect } from 'react';
import { Fade } from 'react-awesome-reveal';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';

// i18next
import { useTranslation } from 'react-i18next';

// Styles
import './AboutPage.styles.css';

// Components
import AboutCardComponent from './../../components/AboutCardComponent/AboutCardComponent';
import BreadcrumbComponent from './../../components/BreadcrumbComponent/BreadcrumbComponent';

const AboutPage = () => {
	// i18next
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang);
		// eslint-disable-next-line
	}, [lang]);

	const breadcrumbItems = [
		{
			title: t('words:breadcrumb.home'),
			href: `/${lang}`,
			isActive: false,
		},
		{
			title: t('words:breadcrumb.aboutUs'),
			href: '',
			isActive: true,
		},
	];

	// Redux
	const { aboutData, reasons, isAboutDataLoading } = useSelector(
		(state) => state.aboutData
	);

	// Scroll To Top On Initial Render
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}, [lang]);

	return (
		!isAboutDataLoading && (
			<Container
				fluid
				lang={lang}
				dir={lang === 'en' ? 'ltr' : 'rtl'}
				id='about-us-page'
				className='page p-0'
			>
				{/* Breadcrumb */}
				<BreadcrumbComponent
					title={t('words:breadcrumb.aboutUs')}
					items={breadcrumbItems}
				/>

				<Container>
					{/* Page Main Section */}
					<Row
						xs={1}
						md={2}
						className='mb-5'
						style={{
							minHeight: '50vh',
						}}
					>
						{/* Section Image */}
						<Col
							className='image'
							style={{
								'--image-url': `url(${aboutData.image})`,
							}}
						></Col>

						{/* Section Text */}
						<Col className='text-container overflow-hidden d-flex flex-column justify-content-center'>
							<Fade direction={lang === 'en' ? 'left' : 'right'} delay='10'>
								<h3 className='title text-uppercase'>{aboutData.title}</h3>

								<p
									className='description'
									dangerouslySetInnerHTML={{ __html: aboutData.description }}
								></p>
							</Fade>
						</Col>
					</Row>

					{/* Reasons To Choose Us */}
					<Row className='g-4'>
						<Col xs={12} className='section-subtitle text-center pt-5'>
							{t('words:whyChooseUs')}
						</Col>

						{reasons.map((reason, index) => (
							<Col
								key={index}
								xs={12}
								sm={6}
								lg={4}
								className='overflow-hidden'
							>
								<Fade direction='up' delay={`${index * 10}`}>
									<AboutCardComponent aboutDetail={reason} />
								</Fade>
							</Col>
						))}
					</Row>
				</Container>
			</Container>
		)
	);
};

export default AboutPage;
