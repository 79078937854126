import axios from 'axios';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useRef } from 'react';
import { Fade } from 'react-awesome-reveal';
import {
	Col,
	Container,
	FormGroup,
	FormLabel,
	FormText,
	Row,
	Spinner,
} from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { object, string } from 'yup';
import { constructURL } from '../../helpers/general';

// Redux
import { useSelector } from 'react-redux';

// i18next
import { useTranslation } from 'react-i18next';

// Styles
import './CateringPage.styles.css';

// Components
import AboutSectionComponent from '../../components/AboutSectionComponent/AboutSectionComponent';
import ButtonComponent from '../../components/ButtonComponent/ButtonComponent';
import BreadcrumbComponent from './../../components/BreadcrumbComponent/BreadcrumbComponent';
import MenuCategoryComponent from './../../components/MenuCategoryComponent/MenuCategoryComponent';

const CateringPage = () => {
	// i18next
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang);
		// eslint-disable-next-line
	}, [lang]);

	const breadcrumbItems = [
		{
			title: t('words:breadcrumb.home'),
			href: `/${lang}`,
			isActive: false,
		},
		{
			title: t('words:breadcrumb.catering'),
			href: '',
			isActive: true,
		},
	];

	// Redux
	const { catering, cateringPageDetails, isCateringLoading } = useSelector(
		(state) => state.catering
	);

	// Refs
	const firstNameRef = useRef(null);
	const lastNameRef = useRef(null);
	const emailRef = useRef(null);
	const phoneRef = useRef(null);
	const detailsRef = useRef(null);

	// Schema
	const reservationSchema = object().shape({
		fname: string()
			.min(2, t('validations:firstName.min', { min: 2 }))
			.max(100, t('validations:firstName.max', { max: 100 }))
			.required(t('validations:firstName.required')),
		lname: string()
			.min(2, t('validations:lastName.min', { min: 2 }))
			.max(100, t('validations:lastName.max', { max: 100 }))
			.required(t('validations:lastName.required')),
		email: string()
			.email(t('validations:email.format'))
			.required(t('validations:email.required')),
		phone: string()
			.min(6, t('validations:phone.min', { min: 6 }))
			.matches(/^[0-9+]+/, t('validations:phone.format'))
			.required(t('validations:phone.required')),
		details: string()
			.min(2, t('validations:details.min', { min: 2 }))
			.max(1000, t('validations:details.max', { max: 1000 }))
			.required(t('validations:details.required')),
	});

	// Handle Form Errors
	const displayErrors = (fieldName) => {
		switch (fieldName) {
			case 'fname':
				firstNameRef.current.classList.add('is-invalid');
				break;

			case 'lname':
				lastNameRef.current.classList.add('is-invalid');
				break;

			case 'email':
				emailRef.current.classList.add('is-invalid');
				break;

			case 'phone':
				phoneRef.current.classList.add('is-invalid');
				break;

			case 'details':
				detailsRef.current.classList.add('is-invalid');
				break;

			default:
				break;
		}
	};

	// Display Form Errors
	const displayToast = (statusCode, message) => {
		switch (statusCode) {
			case 200:
				toast.success(message);
				break;
			case 400:
				toast.error(message);
				break;
			default:
				toast.error(t('sentences:errors.default'));
				break;
		}
	};

	const submitReservationForm = async (
		values,
		setSubmitting,
		resetForm,
		language = 'en'
	) => {
		axios({
			method: 'post',
			url: constructURL('reservation'),
			data: {
				fname: values.fname,
				lname: values.lname,
				email: values.email,
				phone: values.phone,
				details: values.details,
			},
			headers: { locale: language, 'Content-Type': 'multipart/form-data' },
		})
			.then((response) => {
				// reset submitting
				setSubmitting(false);
				resetForm(true);

				displayToast(response.status, response.data.message);
			})
			.catch((error) => {
				// reset submitting
				setSubmitting(false);
				if (error.response.data.data !== {}) {
					Object.keys(error.response.data.data).forEach((key) => {
						displayErrors(key);
						displayToast(
							error.response.status,
							error.response.data.data[key][0]
						);
					});
				} else {
					displayToast(error.response.status, error.response.data.message);
				}
			});
	};

	// Scroll To Top On Initial Render
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}, [lang]);

	return (
		!isCateringLoading && (
			<Container
				fluid
				lang={lang}
				dir={lang === 'en' ? 'ltr' : 'rtl'}
				id='catering-page'
				className='page p-0'
			>
				{/* Breadcrumb */}
				<BreadcrumbComponent
					title={t('words:breadcrumb.catering')}
					items={breadcrumbItems}
				/>

				<Container>
					{/* Page Main Text */}
					<AboutSectionComponent
						id='about-section'
						title={cateringPageDetails.title}
						description={cateringPageDetails.description}
						images={cateringPageDetails.images}
					/>
					<Row xs={1} className='pb-5'>
						<Col className='d-flex justify-content-center align-items-center'>
							<Fade delay='40' className='w-100'>
								<Formik
									initialValues={{
										fname: '',
										lname: '',
										email: '',
										phone: '',
										details: '',
									}}
									validationSchema={reservationSchema}
									onSubmit={(values, { setSubmitting, resetForm }) => {
										setSubmitting(true);
										submitReservationForm(
											values,
											setSubmitting,
											resetForm,
											lang
										);
									}}
								>
									{({
										values,
										errors,
										touched,
										handleChange,
										handleBlur,
										handleSubmit,
										isSubmitting,
										setFieldValue,
									}) => (
										<Form
											onSubmit={(event) => {
												event.preventDefault();
												handleSubmit();
											}}
											className='p-4 overflow-hidden w-100'
										>
											{/* Form Main Title */}
											<Row xs={1} className='mb-4'>
												<FormText className='page-title m-0 p-0 pb-2 px-3 mx-auto text-capitalize text-center'>
													{t('sentences:reserveCatering')}
												</FormText>
											</Row>

											<Row xs={1} sm={2} lg={4}>
												{/* First Name */}
												<FormGroup
													as={Col}
													className={`mb-3 animate__animated ${
														lang === 'en'
															? 'animate__fadeInLeft'
															: 'animate__fadeInRight'
													} animate__delay-1s`}
													style={{
														'--animate-delay': '0.5s',
													}}
												>
													<FormLabel
														htmlFor='first_name'
														className='text-capitalize'
													>
														{t('words:labels.firstName')}
													</FormLabel>
													<Field
														id='first_name'
														type='text'
														innerRef={firstNameRef}
														placeholder={t('words:placeholders.firstName')}
														autoComplete='off'
														name='fname'
														onChange={(event) => {
															handleChange(event);
														}}
														onBlur={handleBlur}
														value={values.fname}
														className={`form-control text-capitalize ${
															touched.fname && errors.fname ? 'is-invalid' : ''
														}`}
													/>
													<ErrorMessage
														component='div'
														name='fname'
														className='invalid-feedback'
													/>
												</FormGroup>

												{/* Last Name */}
												<FormGroup
													as={Col}
													className={`mb-3 animate__animated ${
														lang === 'en'
															? 'animate__fadeInRight'
															: 'animate__fadeInLeft'
													} animate__delay-1s`}
													style={{
														'--animate-delay': '0.5s',
													}}
												>
													<FormLabel
														htmlFor='last_name'
														className='text-capitalize'
													>
														{t('words:labels.lastName')}
													</FormLabel>
													<Field
														id='last_name'
														type='text'
														innerRef={lastNameRef}
														placeholder={t('words:placeholders.lastName')}
														autoComplete='off'
														name='lname'
														onChange={(event) => {
															handleChange(event);
														}}
														onBlur={handleBlur}
														value={values.lname}
														className={`form-control text-capitalize ${
															touched.lname && errors.lname ? 'is-invalid' : ''
														}`}
													/>
													<ErrorMessage
														component='div'
														name='lname'
														className='invalid-feedback'
													/>
												</FormGroup>

												{/* Email */}
												<FormGroup
													as={Col}
													className={`mb-3 animate__animated ${
														lang === 'en'
															? 'animate__fadeInLeft'
															: 'animate__fadeInRight'
													} animate__delay-1s`}
													style={{
														'--animate-delay': '0.75s',
													}}
												>
													<FormLabel
														htmlFor='email'
														className='text-capitalize'
													>
														{t('words:labels.email')}
													</FormLabel>
													<Field
														id='email'
														type='email'
														innerRef={emailRef}
														placeholder='mail@domain.com'
														autoComplete='off'
														name='email'
														onChange={(event) => {
															handleChange(event);
														}}
														onBlur={handleBlur}
														value={values.email}
														className={`form-control ${
															touched.email && errors.email ? 'is-invalid' : ''
														}`}
													/>
													<ErrorMessage
														component='div'
														name='email'
														className='invalid-feedback'
													/>
												</FormGroup>

												{/* Mobile Number */}
												<FormGroup
													as={Col}
													className={`mb-3 animate__animated ${
														lang === 'en'
															? 'animate__fadeInRight'
															: 'animate__fadeInLeft'
													} animate__delay-1s`}
													style={{
														'--animate-delay': '0.75s',
													}}
												>
													<FormLabel
														htmlFor='phone'
														className='text-capitalize'
													>
														{t('words:labels.phone')}
													</FormLabel>
													<Field name='phone' innerRef={phoneRef}>
														{(field, form, meta) => (
															<>
																<PhoneInput
																	{...field}
																	id='phone'
																	dir='ltr'
																	ref={phoneRef}
																	placeholder='56 123 0620'
																	defaultCountry='SA'
																	autoComplete='off'
																	onChange={(event) => {
																		setFieldValue('phone', event);
																	}}
																	onBlur={handleBlur}
																	value={values.phone}
																	className={`${
																		field.meta.touched && field.meta.error
																			? 'is-invalid'
																			: ''
																	}`}
																/>
																{field.meta.error && (
																	<Container
																		fluid
																		className='invalid-feedback p-0'
																	>
																		{field.meta.error}
																	</Container>
																)}
															</>
														)}
													</Field>
												</FormGroup>
											</Row>

											<Row xs={1}>
												{/* Details */}
												<FormGroup
													as={Col}
													className={`mb-3 position-relative animate__animated ${
														lang === 'en'
															? 'animate__fadeInLeft'
															: 'animate__fadeInRight'
													} animate__delay-1s`}
													style={{
														'--animate-delay': '1s',
													}}
												>
													<FormLabel
														htmlFor='details'
														className='text-capitalize'
													>
														{t('words:labels.details')}
													</FormLabel>
													<Field
														id='details'
														as='textarea'
														innerRef={detailsRef}
														rows={8}
														style={{
															resize: 'none',
														}}
														placeholder={t('words:placeholders.details')}
														autoComplete='off'
														name='details'
														onChange={(event) => {
															handleChange(event);
														}}
														onBlur={handleBlur}
														value={values.details}
														className={`form-control text-capitalize ${
															touched.details && errors.details
																? 'is-invalid'
																: ''
														}`}
													/>
													<FormText className='text-muted'>
														<span
															className={`${lang === 'en' ? 'me-1' : 'ms-1'}`}
														>
															{new Intl.NumberFormat(
																lang === 'en' ? 'en-US' : 'ar-EG',
																{}
															).format(values.details.length)}
														</span>
														<span>
															{t('words:hints.message.length', {
																max: new Intl.NumberFormat(
																	lang === 'en' ? 'en-US' : 'ar-EG',
																	{}
																).format(1000),
															})}
														</span>
													</FormText>
													<ErrorMessage
														component='div'
														name='details'
														className='invalid-feedback'
													/>
												</FormGroup>
											</Row>

											{/* Submit Form */}
											<FormGroup
												className='d-flex justify-content-center mt-3 animate__animated animate__zoomIn animate__delay-1s'
												style={{
													'--animate-delay': '1.25s',
												}}
											>
												<ButtonComponent
													text={
														isSubmitting
															? t('words:buttons.sending')
															: t('words:buttons.reserveCatering')
													}
													icon={
														isSubmitting ? (
															<Spinner
																animation='grow'
																variant='light'
																size='sm'
																className={`${lang === 'en' ? 'me-2' : 'ms-2'}`}
															/>
														) : (
															<></>
														)
													}
													type='submit'
													disabled={isSubmitting ? true : false}
												/>
											</FormGroup>
										</Form>
									)}
								</Formik>
							</Fade>
						</Col>
					</Row>

					{/* Menu Categories */}
					<Row xs={1} className='g-4 pt-5'>
						{catering.map((menuCategory, index) => (
							<Fade key={index} delay={`${index * 10}`}>
								<Col>
									<MenuCategoryComponent
										menuCategory={menuCategory}
										height='6rem'
									/>
								</Col>
							</Fade>
						))}
					</Row>
				</Container>
			</Container>
		)
	);
};

export default CateringPage;
