import { createSlice } from '@reduxjs/toolkit';

// Slice
export const homeDataSlice = createSlice({
	name: 'homeData',
	initialState: {
		slides: [],
		features: [],
		aboutUs: {},
		catering: {},
		video: {},
		isHomeDataLoading: true,
	},
	reducers: {
		homeDataSuccess: (state, action) => {
			state.slides = action.payload['slider'];
			state.features = action.payload['features'];
			state.aboutUs = action.payload['about_us'];
			state.catering = action.payload['catering'];
			state.video = action.payload['video'];
			state.isHomeDataLoading = false;
		},

		startLoading: (state) => {
			state.isHomeDataLoading = true;
		},

		hasError: (state, action) => {
			state.error = action.payload;
			state.isHomeDataLoading = false;
		},
	},
});

export default homeDataSlice.reducer;
