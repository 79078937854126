import React, { useEffect, useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

// i18n
import { useTranslation } from 'react-i18next';

// Styles
import './AboutSectionComponent.styles.css';

// Components
import ButtonComponent from './../ButtonComponent/ButtonComponent';
import LightboxComponent from './../LightboxComponent/LightboxComponent';

const AboutSectionComponent = ({
	id = '',
	subTitle = '',
	title = '',
	description = '',
	images = [],
	hasButton = false,
	buttonRoute = '',
	pathname = '<object>.path',
}) => {
	// i18next
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang);
		// eslint-disable-next-line
	}, [lang]);

	// States
	const [lightbox, setLightbox] = useState({ isOpen: false, index: 0 });

	return (
		<Container id={id} className='about-section position-relative'>
			<Row xs={1} lg={2} className='g-3'>
				{/* Section Images */}
				<Col className='images-container'>
					<Row
						xs={images.filter((image, _) => image !== null).length >= 2 ? 2 : 1}
					>
						{images
							.filter((_, index) => index < 4)
							.map(
								(image, index) =>
									image && (
										<Col key={index} className='image-container'>
											<Fade delay={`${index * 30}`}>
												<Container
													fluid
													className='image overflow-hidden position-relative w-100 p-0'
												>
													<Image
														fluid
														src={image.path ?? image}
														alt='about thumbnail'
														className='text-capitalize w-100 h-100'
														onClick={() =>
															setLightbox({
																isOpen: true,
																index: index,
															})
														}
													/>
												</Container>
											</Fade>
										</Col>
									)
							)}

						{/* Lightbox Container */}
						<LightboxComponent
							gallery={images}
							pathname={pathname}
							lightbox={lightbox}
							setLightbox={setLightbox}
						/>
					</Row>
				</Col>

				{/* Section Text */}
				<Col className='text-container overflow-hidden d-flex flex-column justify-content-center'>
					<Fade direction={lang === 'en' ? 'left' : 'right'} delay='10'>
						{subTitle && (
							<p className='section-subtitle subtitle'>{subTitle}</p>
						)}

						<h3 className='title text-uppercase'>{title}</h3>

						<p
							dangerouslySetInnerHTML={{ __html: description }}
							className='description'
						></p>

						{hasButton && (
							<ButtonComponent
								text={t('words:buttons.viewMore')}
								link={buttonRoute}
								className='button'
								styles={{
									button: {
										width: 'fit-content',
									},
								}}
							/>
						)}
					</Fade>
				</Col>
			</Row>
		</Container>
	);
};

export default AboutSectionComponent;
