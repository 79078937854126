import { createSlice } from '@reduxjs/toolkit';

// Slice
export const specialOffersDataSlice = createSlice({
	name: 'specialOffersData',
	initialState: {
		offers: [],
		isSpecial: false,
		isSpecialOffersDataLoading: true,
	},
	reducers: {
		specialOffersDataSuccess: (state, action) => {
			state.offers = action.payload['offers'];
			state.isSpecial = action.payload['isSpecial'];
			state.isSpecialOffersDataLoading = false;
		},

		startLoading: (state) => {
			state.isSpecialOffersDataLoading = true;
		},

		hasError: (state, action) => {
			state.error = action.payload;
			state.isSpecialOffersDataLoading = false;
		},
	},
});

export default specialOffersDataSlice.reducer;
