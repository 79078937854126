import React, { useEffect, useRef, useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';

// LightBox
import Lightbox from 'yet-another-react-lightbox';
import {
	Counter,
	Fullscreen,
	Slideshow,
	Thumbnails,
	Zoom,
} from 'yet-another-react-lightbox/plugins';

// i18next
import { useTranslation } from 'react-i18next';

// Styles
import 'yet-another-react-lightbox/plugins/counter.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import 'yet-another-react-lightbox/styles.css';
import './GalleryPage.styles.css';

// Components
import BreadcrumbComponent from './../../components/BreadcrumbComponent/BreadcrumbComponent';

const GalleryPage = () => {
	// i18next
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang);
		// eslint-disable-next-line
	}, [lang]);

	const breadcrumbItems = [
		{
			title: t('words:breadcrumb.home'),
			href: `/${lang}`,
			isActive: false,
		},
		{
			title: t('words:breadcrumb.gallery'),
			href: '',
			isActive: true,
		},
	];

	// States
	const [lightbox, setLightbox] = useState({ isOpen: false, index: 0 });

	// Refs
	const thumbnailsRef = useRef(null);
	const slideshowRef = useRef(null);
	const fullscreenRef = useRef(null);
	const zoomRef = useRef(null);

	// Redux
	const { gallery, isGalleryLoading } = useSelector((state) => state.gallery);

	// Scroll To Top On Initial Render
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}, [lang]);

	return (
		!isGalleryLoading && (
			<Container
				fluid
				lang={lang}
				dir={lang === 'en' ? 'ltr' : 'rtl'}
				id='gallery-page'
				className='page p-0'
			>
				{/* Breadcrumb */}
				<BreadcrumbComponent
					title={t('words:breadcrumb.gallery')}
					items={breadcrumbItems}
				/>

				<Container>
					{/* Images Container */}
					<Row xs={1} sm={2} md={3} lg={4} className='g-4'>
						{/* Actual Images */}
						{gallery.map((galleryItem, index) => (
							<Fade key={index} direction='up' delay={`${index * 10}`}>
								<Col className='overflow-hidden'>
									<Image
										fluid
										src={galleryItem.image}
										alt='gallery image'
										className='text-capitalize w-100'
										onClick={() => setLightbox({ isOpen: true, index: index })}
									/>
								</Col>
							</Fade>
						))}

						{/* Lightbox Container */}
						<Lightbox
							plugins={[Thumbnails, Counter, Slideshow, Fullscreen, Zoom]}
							open={lightbox.isOpen}
							close={() => setLightbox({ isOpen: !lightbox.isOpen, index: 0 })}
							index={lightbox.index}
							slides={gallery.map((galleryItem, index) => ({
								type: 'image',
								src: galleryItem.image,
								alt: `image #${index}`,
								imageFit: 'contain',
							}))}
							counter={{
								style: {
									left: lang === 'en' ? 0 : 'unset',
									right: lang === 'en' ? 'unset' : 0,
									bottom: 0,
									top: 'unset',
								},
							}}
							thumbnails={{
								ref: thumbnailsRef,
								showToggle: true,
								imageFit: 'cover',
							}}
							slideshow={{ ref: slideshowRef, autoplay: false, delay: 2000 }}
							fullscreen={{ ref: fullscreenRef }}
							zoom={{ ref: zoomRef, scrollToZoom: true }}
							on={{
								click: () => {
									// Thumbnail
									(thumbnailsRef.current?.visible
										? thumbnailsRef.current?.hide
										: thumbnailsRef.current?.show)?.();

									// Slideshow
									(slideshowRef.current?.playing
										? slideshowRef.current?.pause
										: slideshowRef.current?.play)?.();

									// Fullscreen
									fullscreenRef.current?.enter();

									// Zoom
									zoomRef.current?.zoomIn();
								},
							}}
						/>
					</Row>
				</Container>
			</Container>
		)
	);
};

export default GalleryPage;
