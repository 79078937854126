import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

// Styles
import 'react-toastify/dist/ReactToastify.css';

// Components
import GoToTopComponent from '../../components/GoToTopComponent/GoToTopComponent';
import MetaTagsComponent from '../../components/MetaTagsComponent/MetaTagsComponent';
import WhatsappIconComponent from '../../components/WhatsappIconComponent/WhatsappIconComponent';
import FooterComponent from './../../components/FooterComponent/FooterComponent';
import NavbarComponent from './../../components/NavbarComponent/NavbarComponent';

const MainTemplatePage = ({ children }) => {
	// Handle Language Change
	const { lang } = useParams();
	const location = useLocation();
	const navigate = useNavigate();
	useEffect(() => {
		navigate(
			`${location.pathname.replace(`/${lang}`, lang === 'en' ? '/en' : '/ar')}`
		);
		// eslint-disable-next-line
	}, [lang]);

	return (
		<>
			{/* Page Navbar */}
			<NavbarComponent />

			{/* Current Page Content */}
			{children}

			{/* Page Footer */}
			<FooterComponent />

			{/* Whatsapp Icon */}
			<WhatsappIconComponent />

			{/* Go To Top Button */}
			<GoToTopComponent />

			{/* Meta Tags */}
			<MetaTagsComponent />

			{/* Toast Messages */}
			<ToastContainer
				position='top-right'
				autoClose={6000}
				newestOnTop
				pauseOnHover
				rtl={lang === 'en' ? false : true}
			/>
		</>
	);
};

export default MainTemplatePage;
