import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

// Styles
import './FeatureCardComponent.styles.css';

// Components

const FeatureCardComponent = ({ feature }) => {
	const { lang } = useParams();

	return (
		<Container
			lang={lang}
			dir={lang === 'en' ? 'ltr' : 'rtl'}
			className='feature-card-component'
		>
			<Row className='g-2'>
				{/* Image Container */}
				<Col xs={2} sm={3}>
					<Image
						fluid
						src={feature.image}
						alt='feature icon'
						className='text-capitalize'
					/>
				</Col>

				{/* Details Separator */}
				<Col xs={1}></Col>

				{/* Text Container */}
				<Col xs={9} sm={8} className='text-container'>
					{/* Card Title */}
					<h3
						className='title text-limit text-uppercase'
						style={{
							'--lines': 1,
						}}
					>
						{feature.title}
					</h3>

					{/* Card Description */}
					<Container
						fluid
						className='description text-limit p-0'
						style={{
							'--lines': 2,
						}}
						dangerouslySetInnerHTML={{
							__html: feature.description.replace('pre>', 'p>'),
						}}
					></Container>
				</Col>
			</Row>
		</Container>
	);
};

export default FeatureCardComponent;
