import { createSlice } from '@reduxjs/toolkit';

// Slice
export const menuSlice = createSlice({
	name: 'menu',
	initialState: {
		menu: [],
		isMenuLoading: true,
	},
	reducers: {
		menuSuccess: (state, action) => {
			state.menu = action.payload;
			state.isMenuLoading = false;
		},

		startLoading: (state) => {
			state.isMenuLoading = true;
		},

		hasError: (state, action) => {
			state.error = action.payload;
			state.isMenuLoading = false;
		},
	},
});

export default menuSlice.reducer;
