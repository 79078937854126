import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

// Pages
import AboutPage from './pages/AboutPage/AboutPage';
import CateringPage from './pages/CateringPage/CateringPage';
import ContactPage from './pages/ContactPage/ContactPage';
import GalleryPage from './pages/GalleryPage/GalleryPage';
import HomePage from './pages/HomePage/HomePage';
import MainTemplatePage from './pages/MainTemplatePage/MainTemplatePage';
import MenuPage from './pages/MenuPage/MenuPage';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import RamadanOffersPage from './pages/RamadanOffersPage/RamadanOffersPage';
import SpecialOffersPage from './pages/SpecialOffersPage/SpecialOffersPage';

const App = () => {
	return (
		<BrowserRouter>
			{/* Available Routes */}
			<Routes>
				{/* Home Page */}
				<Route path='/' element={<Navigate to='/ar' />} />
				<Route
					exact
					path='/:lang'
					element={
						<MainTemplatePage>
							<HomePage />
						</MainTemplatePage>
					}
				/>

				{/* About Page */}
				<Route
					path='/:lang/about-us'
					element={
						<MainTemplatePage>
							<AboutPage />
						</MainTemplatePage>
					}
				/>

				{/* Contact Page */}
				<Route
					path='/:lang/contact-us'
					element={
						<MainTemplatePage>
							<ContactPage />
						</MainTemplatePage>
					}
				/>

				{/* Menu Page */}
				<Route
					path='/:lang/menu'
					element={
						<MainTemplatePage>
							<MenuPage />
						</MainTemplatePage>
					}
				/>

				{/* Catering Page */}
				<Route
					path='/:lang/catering'
					element={
						<MainTemplatePage>
							<CateringPage />
						</MainTemplatePage>
					}
				/>

				{/* Gallery Page */}
				<Route
					path='/:lang/gallery'
					element={
						<MainTemplatePage>
							<GalleryPage />
						</MainTemplatePage>
					}
				/>

				{/* Ramadan Offers Page */}
				<Route
					path='/:lang/ramadan-offers'
					element={
						<MainTemplatePage>
							<RamadanOffersPage />
						</MainTemplatePage>
					}
				/>

				{/* Special Offers Page */}
				<Route
					path='/:lang/special-offers'
					element={
						<MainTemplatePage>
							<SpecialOffersPage />
						</MainTemplatePage>
					}
				/>

				{/* Not Found Page: Error 404 */}
				<Route path='/:lang/*' element={<NotFoundPage />} />
			</Routes>
		</BrowserRouter>
	);
};

export default App;
