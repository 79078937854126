import axios from 'axios';

// Reducers
import { openingTimesSlice } from '../reducers/openingTimes.reducer';

// Actions
const { openingTimesSuccess, startLoading, hasError } =
	openingTimesSlice.actions;
export const fetchOpeningTimes =
	(
		url = 'https://demoyoursite.net/asmaa/adam-rest-back/public/api/opening-times',
		language = 'en'
	) =>
	async (dispatch) => {
		dispatch(startLoading());

		await axios({
			method: 'get',
			url: url,
			headers: {
				locale: language,
			},
		})
			.then((response) => {
				dispatch(openingTimesSuccess(response.data.data));
			})
			.catch((error) => dispatch(hasError(error.message)));
	};
