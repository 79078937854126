import { createSlice } from '@reduxjs/toolkit';

// Slice
export const contactsSlice = createSlice({
	name: 'contactsData',
	initialState: {
		mobiles: [],
		telephones: [],
		emails: [],
		socials: [],
		isContactsLoading: true,
	},
	reducers: {
		contactsSuccess: (state, action) => {
			state.mobiles = action.payload.mobiles;
			state.telephones = action.payload.telephones;
			state.emails = action.payload.emails;
			state.socials = action.payload.socials;
			state.isContactsLoading = false;
		},

		startLoading: (state) => {
			state.isContactsLoading = true;
		},

		hasError: (state, action) => {
			state.error = action.payload;
			state.isContactsLoading = false;
		},
	},
});

export default contactsSlice.reducer;
