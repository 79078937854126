import React from 'react';
import { Modal } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

// Styles
import './VideoModalComponent.styles.css';

// Components

const VideoModalComponent = ({ video, ...props }) => {
	const { lang } = useParams();

	return (
		<Modal
			lang={lang}
			dir={lang === 'en' ? 'ltr' : 'rtl'}
			{...props}
			size='xl'
			centered
			className='video-modal'
		>
			<Modal.Body>
				<iframe
					width='560'
					height='315'
					src={video.url.replace('/watch?v=', '/embed/')}
					title='YouTube video player'
					frameborder='0'
					allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
					allowfullscreen
				></iframe>
			</Modal.Body>
		</Modal>
	);
};

export default VideoModalComponent;
