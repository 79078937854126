import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';

const MetaTagsComponent = () => {
	const { lang } = useParams();

	// Redux
	const { settings, isSettingsLoading } = useSelector(
		(state) => state.settingsData
	);

	return (
		!isSettingsLoading && (
			<HelmetProvider>
				<Helmet
					htmlAttributes={{
						lang: lang,
						dir: lang === 'en' ? 'ltr' : 'rtl',
					}}
				>
					{/* Open Graph Meta Tags */}
					<meta property='og:title' content={settings.meta_title} />
					<meta property='og:description' content={settings.meta_description} />
					<meta property='og:image' content={settings.meta_logo} />

					{/* Meta Tags */}
					<meta name='title' content={settings.meta_title} />
					<meta name='description' content={settings.meta_description} />
					<meta name='keywords' content={settings.meta_keywords} />

					{/* Tab Title */}
					<title>{settings.website_title}</title>

					{/* Favicon */}
					<link rel='shortcut icon' href={settings.favicon} />
				</Helmet>
			</HelmetProvider>
		)
	);
};

export default MetaTagsComponent;
