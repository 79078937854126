import axios from 'axios';

// Reducers
import { ramadanOffersDataSlice } from '../reducers/ramadanOffersData.reducer';

// Actions
const { ramadanOffersDataSuccess, startLoading, hasError } =
	ramadanOffersDataSlice.actions;
export const fetchRamadanOffersData =
	(
		url = 'https://demoyoursite.net/asmaa/adam-rest-back/public/api/ramadan-offers',
		language = 'en'
	) =>
	async (dispatch) => {
		dispatch(startLoading());

		await axios({
			method: 'get',
			url: url,
			headers: {
				locale: language,
			},
		})
			.then((response) =>
				dispatch(ramadanOffersDataSuccess(response.data.data))
			)

			.catch((error) => dispatch(hasError(error.message)));
	};
