import { createSlice } from '@reduxjs/toolkit';

// Slice
export const gallerySlice = createSlice({
	name: 'gallery',
	initialState: {
		gallery: [],
		isGalleryLoading: true,
	},
	reducers: {
		gallerySuccess: (state, action) => {
			state.gallery = action.payload;
			state.isGalleryLoading = false;
		},

		startLoading: (state) => {
			state.isGalleryLoading = true;
		},

		hasError: (state, action) => {
			state.error = action.payload;
			state.isGalleryLoading = false;
		},
	},
});

export default gallerySlice.reducer;
