import { createSlice } from '@reduxjs/toolkit';

// Slice
export const aboutDataSlice = createSlice({
	name: 'aboutData',
	initialState: {
		aboutData: {},
		reasons: [],
		isAboutDataLoading: true,
	},
	reducers: {
		aboutDataSuccess: (state, action) => {
			state.aboutData = action.payload.about;
			state.reasons = action.payload.why_choose_us;
			state.isAboutDataLoading = false;
		},

		startLoading: (state) => {
			state.isAboutDataLoading = true;
		},

		hasError: (state, action) => {
			state.error = action.payload;
			state.isAboutDataLoading = false;
		},
	},
});

export default aboutDataSlice.reducer;
