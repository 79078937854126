import React from 'react';
import { Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

// Styles
import './AboutCardComponent.styles.css';

// Components

const AboutCardComponent = ({ aboutDetail }) => {
	const { lang } = useParams();

	return (
		<Card
			lang={lang}
			dir={lang === 'en' ? 'ltr' : 'rtl'}
			className='about-card-component overflow-hidden'
		>
			<Card.Img
				fluid='true'
				variant='top'
				src={aboutDetail.image}
				className='image'
			/>
			<Card.Body className='text-center'>
				<Card.Title className='title'>{aboutDetail.title}</Card.Title>
				<Card.Text
					className='description text-limit'
					style={{
						'--lines': 8,
					}}
					dangerouslySetInnerHTML={{
						__html: aboutDetail.description,
					}}
				></Card.Text>
			</Card.Body>
		</Card>
	);
};

export default AboutCardComponent;
