import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

// Styles
import './LoadingComponent.styles.css';

// Components

const LoadingComponent = () => {
	const { lang } = useParams();

	return (
		<Container
			dir={lang === 'en' ? 'ltr' : 'rtl'}
			lang={lang}
			id='loading-component'
			fluid
		>
			<Row>
				<Col className='d-flex justify-content-center align-items-center'>
					<Image
						fluid
						src={require('./../../assets/images/logo.png')}
						alt='company logo'
						className='text-capitalize'
					/>
				</Col>
			</Row>
		</Container>
	);
};

export default LoadingComponent;
