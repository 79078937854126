import AOS from 'aos';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

// Fonts
import '@fontsource/cairo';
import '@fontsource/gulzar';
import '@fontsource/miniver';
import '@fontsource/roboto';

// i18next
import './i18n';

// Redux Store
import store from './store/index.store';

// Styles
import 'animate.css';
import 'aos/dist/aos.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'normalize.css';
import 'react-phone-number-input/style.css';
import './index.css';
import './styles/PhoneInputComponent.styles.css';

// Components
import App from './App';
import LoadingComponent from './components/LoadingComponent/LoadingComponent';

// AOS Setup
AOS.init({
	duration: 1000,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<Provider store={store}>
		<React.StrictMode>
			<Suspense fallback={<LoadingComponent />}>
				<App />
			</Suspense>
		</React.StrictMode>
	</Provider>
);
