import React, { useEffect } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Autoplay, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Redux
import { useSelector } from 'react-redux';

// Styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './SliderComponent.styles.css';

// Components
import ButtonComponent from './../ButtonComponent/ButtonComponent';

const SliderComponent = () => {
	// i18next
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang);
		// eslint-disable-next-line
	}, [lang]);

	// Redux
	const { slides, isHomeDataLoading } = useSelector((state) => state.homeData);
	const { isRamadan, isRamadanOffersDataLoading } = useSelector(
		(state) => state.ramadanOffersData
	);
	const { isSpecial, isSpecialOffersDataLoading } = useSelector(
		(state) => state.specialOffersData
	);

	return (
		!isHomeDataLoading &&
		!isRamadanOffersDataLoading &&
		!isSpecialOffersDataLoading &&
		slides.length > 0 && (
			<Swiper
				lang={lang}
				dir={lang === 'en' ? 'ltr' : 'rtl'}
				key={lang}
				slidesPerView={1}
				spaceBetween={0}
				loop={true}
				autoplay={{
					delay: 8000,
					disableOnInteraction: false,
					pauseOnMouseEnter: true,
				}}
				grabCursor={true}
				effect={'fade'}
				modules={[Autoplay, EffectFade]}
				className='slider-component'
			>
				{slides
					.filter((slide, _) =>
						// Filter out (ramadan-offers related) & (special-offers related) slides
						slide.isRamadan
							? isRamadan
								? true
								: false
							: slide.isSpecial
							? isSpecial
								? true
								: false
							: true
					)
					.map((slide, index) => (
						// Render slides
						<SwiperSlide key={index} className='slider-container'>
							{/* Slider Image */}
							<Image
								fluid
								key={slide}
								src={slide.image}
								alt='slider image'
								className='text-capitalize w-100 h-100'
								style={{
									objectFit: 'cover',
									objectPosition: 'center',
								}}
							/>

							{/* Slider Text */}
							<Container className='slider-text'>
								<Row xs={1} className='g-1'>
									{/* Subtitle */}
									<Col
										className='section-subtitle subtitle animate__animated animate__fadeInUp animate__delay-1s'
										style={{
											'--animate-delay': '0.75s',
										}}
									>
										{slide.sub_title}
									</Col>

									{/* Main Title */}
									<Col
										className='title text-uppercase animate__animated animate__fadeInUp animate__delay-1s'
										style={{
											'--animate-delay': '1s',
										}}
									>
										{slide.title}
									</Col>

									{/* Action Buttons */}
									<Col>
										<ButtonComponent
											text={
												slide.isRamadan
													? t('words:buttons.viewRamadanOffers')
													: slide.isSpecial
													? t('words:buttons.viewSpecialOffers')
													: t('words:buttons.viewMenu')
											}
											link={
												slide.isRamadan
													? `/${lang}/ramadan-offers`
													: slide.isSpecial
													? `/${lang}/special-offers`
													: `/${lang}/menu`
											}
											className='button animate__animated animate__zoomIn animate__delay-1s'
											styles={{
												button: {
													'--animate-delay': '1.25s',
												},
											}}
										/>
									</Col>
								</Row>
							</Container>
						</SwiperSlide>
					))}
			</Swiper>
		)
	);
};

export default SliderComponent;
