import { createSlice } from '@reduxjs/toolkit';

// Slice
export const settingsSlice = createSlice({
	name: 'settingsData',
	initialState: {
		settings: {},
		isSettingsLoading: true,
	},
	reducers: {
		settingsSuccess: (state, action) => {
			state.settings = action.payload;
			state.isSettingsLoading = false;
		},

		startLoading: (state) => {
			state.isSettingsLoading = true;
		},

		hasError: (state, action) => {
			state.error = action.payload;
			state.isSettingsLoading = false;
		},
	},
});

export default settingsSlice.reducer;
