import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';

// Images
import PlateImage from './../../assets/icons/plate-covered.svg';

// Styles
import './MenuItemComponent.styles.css';

// Components

const MenuItemComponent = ({ menuItem }) => {
	const { lang } = useParams();
	const location = useLocation();

	return (
		<Container as={Card} body className='menu-item px-0'>
			<Row className='g-3'>
				{/* Item Image */}
				<Col
					xs={location.pathname.includes('catering') ? 2 : 3}
					className={`image-container ${
						location.pathname.includes('catering') ? 'icon-container' : ''
					}`}
					style={{
						'--image-url': `url(${menuItem.image ?? PlateImage})`,
					}}
				></Col>

				<Col xs={location.pathname.includes('catering') ? 10 : 9}>
					<Container
						fluid
						className='item-header d-flex justify-content-between align-items-center flex-wrap p-0 px-2 h-100'
					>
						{/* Title */}
						<h3
							className={`title text-limit p-0 m-0 text-uppercase ${
								location.pathname.includes('catering') ? 'full-width' : ''
							}`}
							style={{
								'--lines': 4,
							}}
						>
							{menuItem.title}
						</h3>

						{/* Price */}
						{!location.pathname.includes('catering') && (
							<h4 className='price p-0 m-0'>
								{new Intl.NumberFormat(lang === 'en' ? 'en-US' : 'ar-EG', {
									style: 'currency',
									currency: 'SAR',
								}).format(menuItem.price)}
							</h4>
						)}
					</Container>
				</Col>
			</Row>
		</Container>
	);
};

export default MenuItemComponent;
