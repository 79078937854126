import { createSlice } from '@reduxjs/toolkit';

// Slice
export const openingTimesSlice = createSlice({
	name: 'openingTimes',
	initialState: {
		openingTimes: [],
		isOpeningTimesLoading: true,
	},
	reducers: {
		openingTimesSuccess: (state, action) => {
			state.openingTimes = action.payload;
			state.isOpeningTimesLoading = false;
		},

		startLoading: (state) => {
			state.isOpeningTimesLoading = true;
		},

		hasError: (state, action) => {
			state.error = action.payload;
			state.isOpeningTimesLoading = false;
		},
	},
});

export default openingTimesSlice.reducer;
