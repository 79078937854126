import React, { useEffect, useState } from 'react';
import { Col, Container, Image, ListGroup, Row } from 'react-bootstrap';
import { BsEnvelope, BsPhone } from 'react-icons/bs';
import {
	FaFacebookF,
	FaGlobe,
	FaInstagram,
	FaLinkedinIn,
	FaTwitter,
	FaYoutube,
} from 'react-icons/fa';
import { FiPhoneCall } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { checkURL } from './../../helpers/general';

// i18n
import { useTranslation } from 'react-i18next';

// Styles
import 'swiper/css';
import 'swiper/css/lazy';
import './FooterComponent.styles.css';

// Components
import LightboxComponent from '../LightboxComponent/LightboxComponent';
import IconTextComponent from './../IconTextComponent/IconTextComponent';

const FooterComponent = () => {
	// i18n
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang);

		// eslint-disable-next-line
	}, [lang]);

	// Redux
	const { settings, isSettingsLoading } = useSelector(
		(state) => state.settingsData
	);
	const { mobiles, telephones, emails, socials, isContactsLoading } =
		useSelector((state) => state.contactsData);
	const { gallery, isGalleryLoading } = useSelector((state) => state.gallery);

	// States
	const [lightbox, setLightbox] = useState({ isOpen: false, index: 0 });

	const ICON = { SIZE: 24 };

	return (
		<>
			<Container
				lang={lang}
				dir={lang === 'en' ? 'ltr' : 'rtl'}
				as='footer'
				id='footer-component'
				fluid
				className='position-relative text-bg-danger'
				style={{
					'--image-url': `url(${settings.footer_img})`,
				}}
			>
				<Container
					fluid='md'
					className='position-relative text-white'
					style={{
						zIndex: '10',
					}}
				>
					{!isSettingsLoading && !isContactsLoading && !isGalleryLoading && (
						<>
							<Row xs={1} className='justify-content-center g-4'>
								<Col>
									<Row className='g-4'>
										{/* Company Info */}
										<Col xs={12} sm={8} md={6} className='overflow-hidden'>
											<Row xs={1} className='g-3'>
												{/* Company Logo */}
												<Col lg={3} className='d-flex'>
													<Link
														to={`/${lang}`}
														target='_top'
														style={{
															textDecoration: 'none',
														}}
														onClick={() =>
															window.scrollTo({
																top: 0,
																left: 0,
																behavior: 'smooth',
															})
														}
													>
														<Image
															src={settings.white_logo}
															alt='company logo'
															className='logo text-capitalize'
														/>
													</Link>
												</Col>

												{/* Company Title */}
												<Col
													className='title display-6 fw-bold text-capitalize'
													style={{
														fontSize: '22px',
													}}
												>
													{settings.website_title}
												</Col>

												{/* Company Address */}
												<Col>{settings.address}</Col>
											</Row>
										</Col>

										{/* Important Links */}
										<Col xs={12} sm={4} md={2} className='overflow-hidden'>
											<Row xs={1} className='g-3'>
												<Col
													className='title fw-bold text-capitalize'
													style={{
														fontSize: '18px',
													}}
												>
													{t('words:footer.headers.links')}
												</Col>
												<Row xs={1} as='ul' className='pt-2'>
													{/* Menu */}
													<Col as='li'>
														<Link
															to={`/${lang}/menu`}
															className='text-capitalize d-block'
															onClick={() =>
																window.scrollTo({
																	top: 0,
																	left: 0,
																	behavior: 'smooth',
																})
															}
														>
															{t('words:footer.menu')}
														</Link>
													</Col>

													{/* Catering */}
													<Col as='li'>
														<Link
															as='li'
															to={`/${lang}/catering`}
															className='text-capitalize d-block'
															onClick={() =>
																window.scrollTo({
																	top: 0,
																	left: 0,
																	behavior: 'smooth',
																})
															}
														>
															{t('words:footer.catering')}
														</Link>
													</Col>

													{/* About Us */}
													<Col as='li'>
														<Link
															as='li'
															to={`/${lang}/about-us`}
															className='text-capitalize d-block'
															onClick={() =>
																window.scrollTo({
																	top: 0,
																	left: 0,
																	behavior: 'smooth',
																})
															}
														>
															{t('words:footer.aboutUs')}
														</Link>
													</Col>

													{/* Contact Us */}
													<Col as='li'>
														<Link
															as='li'
															to={`/${lang}/contact-us`}
															className='text-capitalize d-block'
															onClick={() =>
																window.scrollTo({
																	top: 0,
																	left: 0,
																	behavior: 'smooth',
																})
															}
														>
															{t('words:footer.contactUs')}
														</Link>
													</Col>
												</Row>
											</Row>
										</Col>

										{/* Gallery */}
										<Col xs={12} md={4} className='gallery overflow-hidden'>
											<Row xs={1} className='g-3'>
												<Col
													className='title fw-bold text-capitalize'
													style={{
														fontSize: '18px',
													}}
												>
													{t('words:footer.headers.gallery')}
												</Col>
												<Col>
													<Swiper
														lang={lang}
														dir={lang === 'en' ? 'ltr' : 'rtl'}
														key={lang}
														lazy={true}
														slidesPerView={2}
														spaceBetween={20}
														loop={true}
														autoplay={{
															delay: 2500,
															disableOnInteraction: false,
															pauseOnMouseEnter: true,
														}}
														breakpoints={{
															576: {
																slidesPerView: 4,
																spaceBetween: 20,
															},
															768: {
																slidesPerView: 2,
																spaceBetween: 20,
															},
															1200: {
																slidesPerView: 4,
																spaceBetween: 20,
															},
														}}
														modules={[Autoplay]}
														className='footer-slider-component'
													>
														{gallery.map((galleryItem, index) => (
															<SwiperSlide key={index}>
																<Image
																	fluid
																	thumbnail
																	src={galleryItem.image}
																	alt='gallery image'
																	className='text-capitalize d-block w-100 h-100'
																	onClick={() =>
																		setLightbox({
																			isOpen: true,
																			index: index,
																		})
																	}
																/>
															</SwiperSlide>
														))}
													</Swiper>

													{/* Lightbox Container */}
													<LightboxComponent
														gallery={gallery}
														lightbox={lightbox}
														setLightbox={setLightbox}
													/>
												</Col>
											</Row>
										</Col>
									</Row>
								</Col>

								<Col>
									<Row xs={1} md={2} className='g-2'>
										{/* Contacts */}
										<Col className='overflow-hidden'>
											<Row xs={1} className='g-3'>
												<Col
													className='title fw-bold text-capitalize'
													style={{
														fontSize: '18px',
													}}
												>
													{t('words:footer.headers.contactUs')}
												</Col>
												<Col>
													<Row as='ul' className='g-1'>
														{/* Telephones */}
														{telephones.length > 0 && (
															<Col xs={12} lg={6} as='li'>
																<IconTextComponent
																	icon={<FiPhoneCall />}
																	text={telephones
																		.filter((_, index) => index < 1)
																		.map((telephone, index) => (
																			<Container
																				fluid
																				key={index}
																				className='value p-0'
																				style={{
																					direction: 'ltr',
																				}}
																			>
																				<a href={`tel:${telephone.contact}`}>
																					{telephone.contact}
																				</a>
																			</Container>
																		))}
																	isCentered={false}
																	styles={{
																		container: {
																			'--size': ICON.SIZE,
																			marginBottom: '0.5rem',
																		},
																	}}
																/>
															</Col>
														)}

														{/* Phones */}
														{mobiles.length > 0 && (
															<Col xs={12} lg={6} as='li'>
																<IconTextComponent
																	icon={<BsPhone />}
																	text={mobiles
																		.filter((_, index) => index < 1)
																		.map((mobile, index) => (
																			<Container
																				fluid
																				key={index}
																				className='value p-0'
																				style={{
																					direction: 'ltr',
																				}}
																			>
																				<a href={`tel:${mobile.contact}`}>
																					{mobile.contact}
																				</a>
																			</Container>
																		))}
																	isCentered={false}
																	styles={{
																		container: {
																			'--size': ICON.SIZE,
																			marginBottom: '0.5rem',
																		},
																	}}
																/>
															</Col>
														)}

														{/* Emails */}
														{emails.length > 0 && (
															<Col xs={12} as='li'>
																<IconTextComponent
																	icon={<BsEnvelope />}
																	text={emails
																		.filter((_, index) => index < 1)
																		.map((email, index) => (
																			<Container
																				fluid
																				key={index}
																				className='value p-0'
																			>
																				<a href={`mailto:${email.contact}`}>
																					{email.contact}
																				</a>
																			</Container>
																		))}
																	isCentered={false}
																	isCapitalized={false}
																	styles={{
																		container: {
																			'--size': ICON.SIZE,
																		},
																	}}
																/>
															</Col>
														)}
													</Row>
												</Col>
											</Row>
										</Col>

										<Col>
											<Row xs={1} className='g-2 h-100'>
												{/* Social Media Accounts */}
												<Col
													as={ListGroup}
													horizontal
													className='social-accounts d-flex align-items-center justify-content-center px-0'
												>
													{socials.map((social, index) => (
														<ListGroup.Item
															key={index}
															as='a'
															href={checkURL(social.contact)}
															target='_blank'
															style={{
																'--icon-color': social.contact.includes(
																	'facebook'
																)
																	? '#1877f2'
																	: social.contact.includes('twitter')
																	? '#1da1f2'
																	: social.contact.includes('linkedin')
																	? '#0077b5'
																	: social.contact.includes('instagram')
																	? '#f56040'
																	: social.contact.includes('youtube')
																	? '#ff0000'
																	: '#34bf49',
															}}
														>
															{social.contact.includes('facebook') ? (
																<FaFacebookF size={20} />
															) : social.contact.includes('twitter') ? (
																<FaTwitter size={20} />
															) : social.contact.includes('linkedin') ? (
																<FaLinkedinIn size={20} />
															) : social.contact.includes('instagram') ? (
																<FaInstagram size={20} />
															) : social.contact.includes('youtube') ? (
																<FaYoutube size={20} />
															) : (
																<FaGlobe size={20} />
															)}
														</ListGroup.Item>
													))}
												</Col>

												{/* Copyrights */}
												<Col
													className='display-6 d-flex align-items-center justify-content-center'
													style={{
														fontSize: '0.8rem',
													}}
												>
													{settings.copyrights}
												</Col>
											</Row>
										</Col>
									</Row>
								</Col>
							</Row>
						</>
					)}
				</Container>
			</Container>

			{/* Company Signature */}
			<Container
				lang={lang}
				dir={lang === 'en' ? 'ltr' : 'rtl'}
				fluid
				className='company-signature'
			>
				<Row>
					<Col className='p-3 py-2 m-0 bg-dark text-white text-center align-middle'>
						<p
							className='m-0 fw-lighter text-capitalize'
							style={{
								fontSize: '0.8rem',
							}}
						>
							{t('words:copyrights.developed')}
							<a
								href='https://marwan.tech/ar/service-request'
								target='_blank'
								rel='noreferrer'
								className='fw-light'
							>
								{t('words:copyrights.company')}
							</a>
						</p>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default FooterComponent;
