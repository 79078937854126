import { createSlice } from '@reduxjs/toolkit';

// Slice
export const ramadanOffersDataSlice = createSlice({
	name: 'ramadanOffersData',
	initialState: {
		offers: [],
		isRamadan: false,
		isRamadanOffersDataLoading: true,
	},
	reducers: {
		ramadanOffersDataSuccess: (state, action) => {
			state.offers = action.payload['offers'];
			state.isRamadan = action.payload['isRamadan'];
			state.isRamadanOffersDataLoading = false;
		},

		startLoading: (state) => {
			state.isRamadanOffersDataLoading = true;
		},

		hasError: (state, action) => {
			state.error = action.payload;
			state.isRamadanOffersDataLoading = false;
		},
	},
});

export default ramadanOffersDataSlice.reducer;
